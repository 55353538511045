export const MainNav = [
    {
        icon: 'lnr-database',
        label: 'Tenants',
        to: '#/tenants',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Feedback',
        to: '#/feedback',
    },{
        icon: 'pe-7s-note2',
        label: 'Insurance Companies',
        to: '#/insurance-company',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Insurance Company Request',
        to: '#/insurance-company-request',
    },
    /*{
        icon: 'pe-7s-note2',
        label: 'Language',
        to: '#/language',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Subject',
        to: '#/subject',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Chapters',
        to: '#/chapters',
    },*/
    {
        icon: 'pe-7s-note2',
        label: 'Admin Profile',
        to: '#/profile',
    },
    {
        icon: 'lnr-cog',
        label: 'Guide',
        to: '#/guide',
    }
];
